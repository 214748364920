import { AuthProvider } from ".";
import { WebAuth } from "auth0-js";
import {
  User,
  setProfileData,
  removeProfileData,
  getProfileData,
} from "./cache";

const webAuth = new WebAuth({
  domain: process.env.ELM_APP_AUTH0_DOMAIN,
  clientID: process.env.ELM_APP_AUTH0_CLIENT_ID,
  scope: "openid profile email",
  responseType: "id_token token",
  redirectUri: window.location.origin,
});

const retryAuth = () => {
  console.log('retrying login')
  removeProfileData();
  webAuth.authorize({ state: window.location.pathname });
};

const logout = () => {
  removeProfileData();
  const homepage = window.location.origin + "/"
  webAuth.logout({ clientID: process.env.ELM_APP_AUTH0_CLIENT_ID, returnTo: homepage });
};

const getUser = (): Promise<User | void> => {
  return getUserFromCache()
    .catch((err) => {
      console.error(err);
      removeProfileData();
      webAuth.authorize({
        state: window.location.pathname,
        // prompt: "none",
      });
    })
    .then((user) => {
      if (user) {
        setProfileData(user);
        return user;
      }
    });
};

function getUserFromCache(): Promise<User> {
  const user = getProfileData();
  if (!user) {
    return getUserFromHash();
  }
  return Promise.resolve(user);
}

function getUserFromHash(): Promise<User> {
  return new Promise((resolve, reject) => {
    webAuth.parseHash({ hash: window.location.hash }, (err, result) => {
      if (err) {
        reject(err);
      } else if (result) {
        const token = result.accessToken;
        const id_token = result.idToken;

        if (result.state)
          window.history.replaceState(null, document.title, result.state);

        webAuth.client.userInfo(token, (err, profile) => {
          if (err) {
            reject(err);
          } else {
            const user = {
              profile: profile,
              token: token,
              id_token: id_token,
            };
            resolve(user);
          }
        });
        removeHash();
      } else {
        throw Error("Could not get user from hash");
      }
    });
  });
}

function removeHash() {
  history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  );
}

export const auth0Provider = (): AuthProvider => ({
  getUser,
  retryAuth,
  logout,
});
