// @ts-nocheck
import * as d3 from "./d3";
import { ScaleLinear, ScaleBand } from "d3-scale";
import { legendColor } from "d3-svg-legend";
import uniq from "lodash/uniq";
import groupBy from "lodash/groupBy";
import tinycolor from "tinycolor2";

const defaultFont = {
  size: 11,
  family:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
  color: "black",
  invertedColor: "white"
};

const defaultColorScheme = ["#c7e0fb", "#91b0ff", "#0066cc"];

interface BarChart {
  config: BarChartConfig;
  values: BarChartValue[];
  colorScheme: string[];
  width: number;
  height: number;
}

interface BarChartConfig {
  axes: {
    label: boolean;
    value: boolean;
  };
  legend: boolean;
  valueLabels: ValueLabelsType | null;
  orientation: Orientation;
  decoration?;
  groupingType?: Grouping;
  connectors: boolean;
}

enum Orientation {
  Bar = "BAR",
  Column = "COLUMN"
}

enum Grouping {
  Group = "GROUP",
  Stack = "STACK",
  FullStack = "FULL_STACK"
}

enum ValueLabelsType {
  Values = "VALUES",
  Percentages = "PERCENTAGES"
}

interface BarChartValue {
  value: number;
  label: string;
  series?: string;
}

export default {
  render(id, chart: BarChart) {
    chart.width = chart.width || 400;
    chart.height = chart.height || 250;
    const isStacked = chart.values.every(hasSeries);
    const margins = {
      top: chart.config.decoration ? 50 : 20,
      right: isStacked && chart.config.legend ? 200 : 20,
      bottom: 70,
      left: 70
    };

    const width = chart.width - margins.left - margins.right;
    const height = chart.height - margins.top - margins.bottom;
    const isBar = chart.config.orientation === "BAR"; // column by default
    const isGroup = chart.config.groupingType === "GROUP"; // stack by default
    const isFull = chart.config.groupingType === "FULL_STACK";

    const colors =
      chart.colorScheme && chart.colorScheme.length
        ? chart.colorScheme
        : defaultColorScheme;

    const columns = getColumns(chart.values, isGroup);

    const valueScale = barChartValueScale(
      chart,
      columns,
      isBar ? width : height,
      !isBar,
      isFull
    );

    const labelScale = barChartLabelScale(
      chart,
      chart.values,
      isBar ? height : width
    );

    let suppLabelScale = null;
    const labels = uniq(chart.values.map(d => d.label));

    if (isGroup) {
      let series = uniq(chart.values.map(d => d.series));

      suppLabelScale = d3
        .scaleBand()
        .domain(series)
        .rangeRound([0, labelScale.bandwidth()])
        .paddingInner(0)
        .paddingOuter(0);
    }

    const colorScale = d3
      .scaleOrdinal(colors)
      .domain(chart.values.map(d => d.series));

    const xAxis = isBar
      ? d3.axisBottom(valueScale).ticks(10)
      : d3.axisBottom(labelScale);

    const yAxis = isBar
      ? d3.axisLeft(valueScale).ticks(10)
      : d3.axisLeft(labelScale);

    const svg = d3
      .select("#" + id)
      .append("svg")
      .attr("width", chart.width)
      .attr("height", chart.height)
      .append("g")
      .attr("transform", "translate(" + margins.left + "," + margins.top + ")");

    const shouldDrawXAxis = isBar
      ? chart.config.axes.value
      : chart.config.axes.label;

    if (shouldDrawXAxis) {
      const xAxisElement = svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      if (!isBar) {
        wrapLabels(labelScale, xAxisElement);
      }
    }

    const shouldDrawYAxis = isBar
      ? chart.config.axes.label
      : chart.config.axes.value;

    if (shouldDrawYAxis) {
      const yAxisElement = svg
        .append("g")
        .attr("class", "y axis")
        .call(yAxis);

      if (isBar) {
        truncateLabels(yAxisElement);
      }
    }

    function wrapLabels(scale, selection) {
      return selection.selectAll(".tick text").call(wrap, scale.step() * 0.95);
    }

    function truncateLabels(selection) {
      return selection.selectAll(".tick text").call(truncate);
    }

    let stacks = getStackBlocks(chart.values, isGroup);
    if (isFull) stacks = stacks.map(ratios(columns));

    // BAR RECTANGLES
    if (!isGroup) {
      // CONNECTORS
      if (chart.config.connectors) {
        const connectors: BarChartValue[][] = Object.values(
          groupBy(stacks, "series")
        );
        svg
          .append("g")
          .attr("class", "connectors")
          .selectAll(".connector")
          .data(connectors)
          .enter()
          .append("g")
          .attr("class", "connector")
          .selectAll("line")
          .data(d => {
            return chunks(d);
          })
          .enter()
          .append("line")
          .attr("x1", ([d, next]) => {
            return isBar ? valueScale(d.end) : labelScale(d.label);
          })
          .attr("x2", ([d, next]) => {
            return isBar
              ? valueScale(next.end)
              : labelScale(next.label) + labelScale.bandwidth();
          })
          .attr("y1", ([d, next]) => {
            return isBar ? labelScale(d.label) : valueScale(d.end);
          })
          .attr("y2", ([d, next]) => {
            return isBar
              ? labelScale(next.label) + labelScale.bandwidth()
              : valueScale(next.end);
          })
          .attr("stroke", "#999")
          .attr("stroke-width", 1)
          .attr("stroke-dasharray", "5 3");
      }

      const bars = svg
        .append("g")
        .attr("class", "bars")
        .selectAll(".bar")
        .data(stacks)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr(
          "y",
          (d: any) => 0.5 + (isBar ? labelScale(d.label) : valueScale(d.end))
        )
        .attr(
          "x",
          (d: any) => 0.5 + (isBar ? valueScale(d.start) : labelScale(d.label))
        )
        .attr("width", (d: any) =>
          isBar ? valueScale(d.end - d.start) : labelScale.bandwidth()
        )
        .attr("height", (d: any) =>
          isBar ? labelScale.bandwidth() : height - valueScale(d.end)
        )
        .style("fill", (d: any) => colorScale(d.series))
        .attr("stroke", "black")
        .attr("stroke-width", "1");
    }

    let groups;
    if (isGroup) {
      groups = svg
        .selectAll(".group")
        .data(labels)
        .enter()
        .append("g")
        .attr("class", "group")
        .attr("transform", (d: string) =>
          isBar
            ? "translate(0," + labelScale(d) + ")"
            : "translate(" + labelScale(d) + ",0)"
        );

      groups
        .append("g")
        .attr("class", "bars")
        .selectAll(".bar")
        .data(d => stacks.filter(stack => stack.label === d))
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr(
          "y",
          d => 0.5 + (isBar ? suppLabelScale(d.series) : valueScale(d.end))
        )
        .attr(
          "x",
          d => 0.5 + (isBar ? valueScale(d.start) : suppLabelScale(d.series))
        )
        .attr("width", d =>
          isBar ? valueScale(d.end - d.start) : suppLabelScale.bandwidth()
        )
        .attr(
          "height",
          isBar ? suppLabelScale.bandwidth() : d => height - valueScale(d.end)
        )
        .style("fill", d => colorScale(d.series))
        .attr("stroke", "black")
        .attr("stroke-width", "1");
    }

    const valueFormat = chart.values.some(({ value }) => isFloat(value))
      ? ",.2f"
      : ",d";

    // SUM LABELS
    if (!isGroup)
      svg
        .append("g")
        .selectAll("text")
        .data(columns)
        .enter()
        .append("text")
        .attr("y", (d: any) =>
          isBar
            ? labelScale(d.label) + labelScale.bandwidth() * 0.5
            : isFull
            ? valueScale(1)
            : valueScale(d.value)
        )
        .attr("x", (d: any) =>
          isBar
            ? isFull
              ? valueScale(1)
              : valueScale(d.value)
            : labelScale(d.label) + labelScale.bandwidth() * 0.5
        )
        .style("text-anchor", isBar ? "left" : "middle")
        .attr("font-size", defaultFont.size)
        .attr("font-family", defaultFont.family)
        .attr("dy", isBar ? "0.5em" : "-0.5em")
        .attr("dx", isBar ? "0.5em" : "0")
        .text((d: any) => d3.format(valueFormat)(d.value));

    if (chart.config.valueLabels) {
      // VALUE LABELS
      const labelGroups = svg
        .append("g")
        .selectAll(".value-label")
        .data(stacks)
        .enter()
        .append("g")
        .attr("class", "value-label");

      if (isGroup) {
        groups
          .selectAll("text")
          .data(d => stacks.filter(stack => stack.label === d))
          .enter()
          .append("text")
          .attr("x", d =>
            isBar
              ? valueScale(d.end)
              : suppLabelScale(d.series) + suppLabelScale.bandwidth() * 0.5
          )
          .attr("y", d =>
            isBar
              ? suppLabelScale(d.series) + suppLabelScale.bandwidth() * 0.5
              : valueScale(d.end)
          )
          .style("text-anchor", isBar ? "left" : "middle")
          .attr("font-size", defaultFont.size)
          .attr("font-family", defaultFont.family)
          .attr("dy", isBar ? "0.4em" : "-0.5em")
          .attr("dx", isBar ? "0.5em" : "0")
          .text(d => d3.format(valueFormat)(d.end));
      } else {
        labelGroups
          .append("text")
          .attr("x", (d: any) =>
            isBar
              ? valueScale(d.start + (d.end - d.start) / 2)
              : labelScale(d.label) + labelScale.bandwidth() * 0.5
          )
          .attr("y", (d: any) =>
            isBar
              ? labelScale(d.label) + labelScale.bandwidth() * 0.5
              : valueScale(d.start + (d.end - d.start) / 2)
          )
          .style("text-anchor", "middle")
          .attr("dy", "0.25em")
          .attr("font-size", defaultFont.size)
          .attr("font-family", defaultFont.family)
          .text((d: any) =>
            chart.config.valueLabels === "PERCENTAGES"
              ? d3.format(".1%")(
                  isFull
                    ? d.value
                    : d.value / columns.find(col => col.label === d.label).value
                )
              : d3.format(valueFormat)(d.value)
          )
          .call(function(selection) {
            selection.each(function(d, i) {
              stacks[i].bbox = (this as any).getBBox();
            });

            // selection.each(function(d, i) {
            //   const otherBoxesInColumn = stacks
            //     .filter((stack, stackIndex) => {
            //       return stackIndex !== i && stack.label === stacks[i].label;
            //     })
            //     .map(stack => stack.bbox);

            //   if (overlap(stacks[i].bbox, otherBoxesInColumn)) {
            //     const columnIndices = stacks.reduce(
            //       (acc, stack, j) =>
            //         stack.label === stacks[i].label ? [...acc, j] : acc,
            //       []
            //     );

            //     selection.each((d, j) => {
            //       if (columnIndices.includes(j)) {
            //         console.log(this)
            //         if (isBar) {
            //           if (j % 2 === 0) {
            //             this.setAttribute('y', d.bbox.y + 20)
            //           } else {
            //             this.setAttribute('y', d.bbox.y - 20)
            //           }
            //         } else {
            //           if (j % 2 === 0) {
            //             this.setAttribute('x', d.bbox.x + 20)
            //           } else {
            //             this.setAttribute('x', d.bbox.x - 20)
            //           }
            //         }
            //         stacks[j].bbox = this.getBBox();
            //       }
            //     })
            //   }
            // });
          });
      }

      if (!isGroup) {
        labelGroups
          .insert("rect", "text")
          .attr("width", (d: any) => d.bbox.width)
          .attr("height", (d: any) => d.bbox.height)
          .attr("x", (d: any) => d.bbox.x)
          .attr("y", (d: any) => d.bbox.y)
          .style("fill", (d: any) => colorScale(d.series));

        labelGroups
          .selectAll("text")
          .style("fill", (d: any) =>
            tinycolor(colorScale(d.series)).isLight()
              ? defaultFont.color
              : defaultFont.invertedColor
          );
      }
      // .each((d) => console.log(d))
    }

    const legend = legendColor()
      .shape("rect")
      .shapePadding(1)
      .scale(colorScale);

    // LEGEND
    if (isStacked && chart.config.legend)
      svg
        .append("g")
        .attr("transform", "translate(" + (width + 20) + ",0)")
        .attr("class", "legend")
        .call(legend)
        .selectAll(".label")
        .attr("font-size", defaultFont.size)
        .attr("font-family", defaultFont.family)
        .attr("dy", "-0.1em")
        .attr("dx", "-0.5em");

    // DECORATIONS
    if (chart.config.decoration) {
      const firstCol = columns[0];
      const lastCol = columns[columns.length - 1];
      const path = d3.path();
      path.moveTo(
        0.5 + labelScale(firstCol.label) + labelScale.bandwidth() * 0.5,
        0.5 + valueScale(firstCol.value) - 20
      );
      path.lineTo(
        0.5 + labelScale(firstCol.label) + labelScale.bandwidth() * 0.5,
        -10.5
      );
      path.lineTo(
        0.5 + labelScale(lastCol.label) + labelScale.bandwidth() * 0.5,
        -10.5
      );
      path.lineTo(
        0.5 + labelScale(lastCol.label) + labelScale.bandwidth() * 0.5,
        0.5 + valueScale(lastCol.value) - 20
      );

      const decoration = svg.append("g").attr("class", "decoration");

      decoration
        .append("path")
        .attr("d", path.toString())
        .attr("stroke", "black")
        .attr("fill", "none");

      decoration
        .append("ellipse")
        .attr("cx", width / 2)
        .attr("cy", -10.5)
        .attr("rx", 25)
        .attr("ry", 15)
        .attr("fill", "white")
        .attr("stroke", "black");

      decoration
        .append("text")
        .attr("x", width / 2)
        .attr("y", -10)
        .style("text-anchor", "middle")
        .attr("font-size", defaultFont.size)
        .attr("font-family", defaultFont.family)
        .attr("dy", "0.4em")
        .text(d3.format(".1%")(chart.config.decoration.value));

      decoration
        .append("path")
        .attr(
          "d",
          d3
            .symbol()
            .size(40)
            .type(d3.symbolTriangle)()
        )
        .attr("fill", "black")
        .attr(
          "transform",
          "translate(" +
            (labelScale(lastCol.label) + labelScale.bandwidth() * 0.5 + 0.8) +
            "," +
            (valueScale(lastCol.value) - 23) +
            ") rotate(180)"
        );

      // console.log(d3.symbolTriangle.draw(svg, 10))
    }
  }
};

function barChartValueScale(
  chart,
  data,
  length,
  isInverted,
  isFull
): ScaleLinear<number, number> {
  const values = data.map(d => d.value);
  const max = Math.max(...values) * 1.1;

  return d3
    .scaleLinear()
    .domain(isFull ? [0, 1] : [0, max])
    .rangeRound(isInverted ? [length, 0] : [0, length]);
}

function barChartLabelScale(chart, data, width): ScaleBand<string> {
  const values = data.map(datum => datum.label);

  return d3
    .scaleBand()
    .domain(values)
    .rangeRound([0, width])
    .paddingInner(0.25)
    .paddingOuter(0.25);
}

function wrap(text, width) {
  text.each(function() {
    var text = d3.select(this),
      words = text
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr("y"),
      dy = parseFloat(text.attr("dy")),
      tspan = text
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", dy + "em");
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if ((tspan.node() as any).getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
      }
    }
  });
}

function truncate(text) {
  text.each(function() {
    var text = d3.select(this);
    var content = text.text();

    if (content.length > 13) text.text(content.slice(0, 10) + "...");
  });
}

function isFloat(n) {
  return n % 1 !== 0;
}

function hasSeries(d) {
  return !!d.series;
}

function getColumns(values, isGroup) {
  return values.every(hasSeries) && !isGroup
    ? Object.entries(values.reduce(accumColumns, {})).map(([label, value]) => ({
        label,
        value
      }))
    : values;
}

function accumColumns(acc, d) {
  return acc[d.label]
    ? { ...acc, [d.label]: acc[d.label] + d.value }
    : { ...acc, [d.label]: d.value };
}

function getStackBlocks(values, isGroup) {
  return values.every(hasSeries) && !isGroup
    ? values.reduce(accumStacks, [])
    : values.map(d => ({
        label: d.label,
        start: 0,
        end: d.value,
        series: d.series
      }));
}

function accumStacks(acc, d) {
  const prevStackIndex = acc.findIndex(a => a.label === d.label);
  return prevStackIndex !== -1
    ? [
        {
          label: d.label,
          start: acc[prevStackIndex].end,
          end: acc[prevStackIndex].end + d.value,
          value: d.value,
          series: d.series
        },
        ...acc
      ]
    : [
        {
          label: d.label,
          start: 0,
          end: d.value,
          value: d.value,
          series: d.series
        },
        ...acc
      ];
}

function overlap(box, boxes) {
  const x1 = box.x;
  const x2 = box.x + box.width;
  const y1 = box.y;
  const y2 = box.y + box.height;
  return boxes.some(otherBox => {
    const x1_ = otherBox.x;
    const x2_ = otherBox.x + otherBox.width;
    const y1_ = otherBox.y;
    const y2_ = otherBox.y + otherBox.height;

    return (
      containsPoint(box, [x1_, y1_]) ||
      containsPoint(box, [x2_, y1_]) ||
      containsPoint(box, [x1_, y2_]) ||
      containsPoint(box, [x2_, y2_]) // ||
      // containsPoint(otherBox, [x1, y1]) ||
      // containsPoint(otherBox, [x2, y1]) ||
      // containsPoint(otherBox, [x1, y2]) ||
      // containsPoint(otherBox, [x2, y2])
    );
  });
}

function containsPoint(box, [x, y]) {
  return (
    box.x <= x &&
    box.x + box.width >= x &&
    box.y <= y &&
    box.y + box.height >= y
  );
}

// function adjust(stacks, isBar) {
//   stacks.forEach((stack, i) => {
//     stack
//   });
// }

function chunks(arr) {
  const result = [];
  for (var i = 0; i < arr.length - 1; i++) {
    result.push([arr[i], arr[i + 1]]);
  }
  return result;
}

function ratios(columns) {
  return function(stack) {
    const column = columns.find(column => column.label === stack.label);
    if (!column || !column.value) return stack;

    return {
      ...stack,
      start: stack.start / column.value,
      end: stack.end / column.value,
      value: stack.value / column.value
    };
  };
}
