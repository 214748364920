import { AuthProvider } from ".";
import Keycloak from "keycloak-js";
import {
  User,
  setProfileData,
  removeProfileData,
  getProfileData,
} from "./cache";

const keycloak = Keycloak({
  url: process.env.ELM_APP_KEYCLOAK_URL,
  realm: process.env.ELM_APP_KEYCLOAK_REALM,
  clientId: process.env.ELM_APP_KEYCLOAK_CLIENT_ID,
});

const logout = () => {
  removeProfileData();
  keycloak.logout();
};

// TODO
const retryAuth = () => {
  removeProfileData();
  keycloak.logout();
};

const getUser = (): Promise<User | void> => {
  return getUserFromCache()
    .catch((err) => {
      console.error(err);
      removeProfileData();
      return keycloak
        .init({
          // silentCheckSsoRedirectUri:
          //   window.location.origin + "/silent-check-sso.html",
          onLoad: "login-required",
          flow: "implicit",
        })
        .then(function (authenticated) {
          return {
            id_token: keycloak.idToken,
            token: keycloak.token,
            profile: keycloak.idTokenParsed,
          };
        })
        .catch(function () {
          console.log("keycloak error");
        });
    })
    .then((user) => {
      if (user) {
        setProfileData(user);
        return user;
      }
    });
};

function getUserFromCache(): Promise<User> {
  const user = getProfileData();
  if (!user) {
    return Promise.reject("User cannot be retrieved from cache");
  }
  return keycloak
    .init({
      token: user.token,
      idToken: user.id_token,
    })
    .then(function (authenticated) {
      return user;
    });
}

export const keycloakProvider = (): AuthProvider => ({ getUser, retryAuth, logout });
