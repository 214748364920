import { execute } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import cssVars from "css-vars-ponyfill";
import Dexie from "dexie";
import hljs from "highlight.js";
import markdown from "highlight.js/lib/languages/markdown";
import tinycolor from "tinycolor2";
import "highlight.js/styles/github-gist.css";

import BarChart from "./charts/barchart";
import { Elm } from "./Main.elm";
import "./Styles/app.scss";
import { AuthProvider, authProvider } from "./auth";

(window as any).hljs = hljs;
hljs.registerLanguage("markdown", markdown);

const db = new Dexie("p2i");
db.version(1).stores({
  entities: "id",
});

db.open();

const defaultPrimaryColor = "#8f83fb";
const defaultAltColor = "#8276ef";
const defaultHeaderColor = "#1f282c";

cssVars();

const auth: AuthProvider = authProvider();
if (!auth) {
  throw Error("auth provider not defined!");
}

const subscriptionEndpoint = `${process.env.ELM_APP_API_HOST}:${process.env.ELM_APP_API_PORT}/`.replace(
  /^http?/,
  "ws"
);

const link = new WebSocketLink({
  uri: subscriptionEndpoint,
  options: {
    reconnect: true,
  },
});

auth
  .getUser()
  .then(flags)
  .then((flags) => {
    const app = Elm.Main.init({
      node: document.getElementById("root"),
      flags,
    });

    setUpPorts(app);
    return app;
  })
  .catch((err) => console.error(err));

function flags(user) {
  return db
    .table("entities")
    .toArray()
    .catch((_) => [])
    .then((entities) => ({
      user,
      apiHost: process.env.ELM_APP_API_HOST,
      apiPort: process.env.ELM_APP_API_PORT
        ? parseInt(process.env.ELM_APP_API_PORT, 10)
        : null,
      entities: entities.map((entity) => entity.json),
    }));
}

/* Elm ports interface */

function setUpPorts(app) {
  if (app.ports.logOut)
    app.ports.logOut.subscribe(() => {
      auth.logout();
    });

    if (app.ports.retryAuth)
      app.ports.retryAuth.subscribe(() => {
        auth.retryAuth();
      });

  if (app.ports.downloadFile)
    app.ports.downloadFile.subscribe(({ filename, url, jwt }) => {
      let link = document.createElement("a");
      link.download = filename;
      link.href = jwt ? url + "?token=" + jwt : url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

  if (app.ports.setProjectColors)
    app.ports.setProjectColors.subscribe(setProjectColors);

  if (app.ports.scrollIntoView)
    app.ports.scrollIntoView.subscribe((id) => {
      const element = document.getElementById(id);

      if (element)
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    });

  if (app.ports.persistEntity)
    app.ports.persistEntity.subscribe((entity) =>
      db
        .table("entities")
        .put(entity)
        .catch((err) => console.error(err))
    );

  if (app.ports.subscribe)
    app.ports.subscribe.subscribe((selectionSet) => {
      const operation = {
        query: selectionSet,
      };
      execute(link, operation).subscribe({
        next: (data) => app.ports.updates.send(data),
        error: (error) => console.error(`graphql subscription error: ${error}`),
        complete: () => console.log("graphql subscription complete"),
      });
    });

  if (app.ports.renderBarChart)
    app.ports.renderBarChart.subscribe(({ id, colorScheme, chart }) => {
      requestAnimationFrame(() => {
        const element = document.getElementById(id);
        if (!element) return;
        element.innerHTML = "";
        const { width } = element.getBoundingClientRect();
        BarChart.render(id, { ...chart, colorScheme, width });
      });
    });

  if (app.ports.selectInput)
    app.ports.selectInput.subscribe((id) => {
      requestAnimationFrame(() => {
        const element: any = document.getElementById(id);
        if (element && element.select) element.select();
      });
    });

  if (app.ports.favicon)
    app.ports.favicon.subscribe((url) => {
      changeFavicon(url);
    });
}

function setProjectColors({ accentColor, headerColor }) {
  cssVars({
    variables: {
      "header-logo-bg": accentColor || defaultPrimaryColor,
      "brand-primary": accentColor || defaultPrimaryColor,
      "brand-primary-alt": accentColor
        ? tinycolor(accentColor).darken(10).desaturate(10).toString()
        : defaultAltColor,
      "header-bg": headerColor || defaultHeaderColor,
    },
  });
}

/* Favicon */

/**
 * Init the page with default favicon, update accourdingly afterwards.
 * (seems to be the only option to get it working with Safari.)
 */
changeFavicon("https://www.data2impact.com/favicon-32x32.png");

/**
 * Updates favicon properties in head.
 * <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
 * <link rel="icon" type="image/png" href="/favicon-32x32.png">
 * <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
 */
function changeFavicon(href: string) {
  const links = [
    { rel: "apple-touch-icon" },
    { rel: "icon", type: "image/png" },
    { rel: "shortcut icon", type: "image/png" },
  ];

  for (const link of links) {
    var element: HTMLLinkElement =
      document.querySelector(`link[rel='${link.rel}']`) ||
      document.createElement("link");
    for (const key in link) {
      element[key] = link[key];
    }
    element.href = `${href}?`;
    document.getElementsByTagName("head")[0].appendChild(element);
  }
}
