export type User = {
  token: string;
  id_token: string;
  profile: any;
};

export function setProfileData({ profile, token, id_token }) {
  localStorage.setItem("profile", JSON.stringify(profile));
  localStorage.setItem("token", token);
  localStorage.setItem("id_token", id_token);
}

export const getProfileData = (): User | undefined => {
  const profile = localStorage.getItem("profile");
  const token = localStorage.getItem("token");
  const id_token = localStorage.getItem("id_token");
  if (!profile || !token || !id_token) {
    return undefined;
  }

  return {
    profile: JSON.parse(profile),
    token,
    id_token,
  };
};

export function removeProfileData() {
  localStorage.removeItem("profile");
  localStorage.removeItem("token");
  localStorage.removeItem("id_token");
}
