import { auth0Provider } from "./auth0";
import { keycloakProvider } from "./keycloak";
import { User } from "./cache";

export type AuthProvider = {
  getUser: () => Promise<User | void>;
  logout: () => void;
  retryAuth: () => void;
};

export const authProvider = (): AuthProvider => {
  switch (process.env.ELM_APP_AUTH_PROVIDER) {
    case "AUTH0":
      return auth0Provider();
    case "KEYCLOAK":
      return keycloakProvider();
    default:
      return auth0Provider();
  }
};
